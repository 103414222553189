import layout from 'components/Layout/locales/vi';
import layoutUser from 'components/LayoutUser/locales/vi';
import groupRadioButton from 'components/Form/GroupRadioButton/locales/vi';
import registerPage from 'containers/OtherPage/RegisterPage/locales/vi';
import modalConfirm from 'components/commons/ModalConfirm/locales/vi';
import modal from 'components/commons/Modal/locales/vi';
import confirmPhone from 'containers/OtherPage/ConfirmNumberPhone/locales/vi';
import updateEmail from 'containers/OtherPage/UpdateEmail/locales/vi';
import table from 'components/commons/Table/locales/vi';
import tagCommon from 'components/commons/TagCommon/locales/vi';

// new page
import documents from 'containers/TesAdminPage/Documents/locales/vi';
import questions from 'containers/TesAdminPage/Questions/locales/vi';
import loginPage from 'containers/OtherPage/LoginPage/locales/vi';
import firstLogin from 'containers/OtherPage/FirstLogin/locales/vi';
import changePasswordPage from 'containers/OtherPage/ChangePasswordPage/locales/vi';
import forgotPasswordPage from 'containers/OtherPage/ForgotPasswordPage/locales/vi';
import forgotSuccessPasswordPage from 'containers/OtherPage/ForgotSuccessPasswordPage/locales/vi';
import employeeManager from 'containers/TesAdminPage/EmployeeManager/locales/vi';
import folders from 'containers/TesAdminPage/Folders/locales/vi';
import contest from 'containers/TesAdminPage/ContestManager/locales/vi';
import student from 'containers/TesAdminPage/ContestManager/locales/vi';
import monitoringAndGrading from 'containers/TesAdminPage/MonitoringAndGrading/locales/vi';
import settingsManagement from 'containers/TesAdminPage/Settings/locales/vi';

// student module
import studentContest from 'containers/TesStudentPage/Contest/locales/vi';
import accountSettings from 'containers/TesStudentPage/AccountSettings/locales/vi';
import contestResult from 'containers/TesStudentPage/Result/locales/en';

//class module
import classRoom from 'containers/TesAdminPage/Class/locales/vi';

import documentStudent from 'containers/TesStudentPage/Documents/locales/vi';

import messageStatus from './message/vi';
import validation from './validation/vi';

export default {
  layout,
  layoutUser,
  registerPage,
  modalConfirm,
  validation,
  groupRadioButton,
  modal,
  table,
  tagCommon,
  //
  messageStatus,
  confirmPhone,
  updateEmail,
  changePasswordPage,

  // new page
  documents,
  questions,
  loginPage,
  firstLogin,
  forgotPasswordPage,
  forgotSuccessPasswordPage,
  employeeManager,
  folders,
  contest,
  student,
  monitoringAndGrading,
  settingsManagement,
  // student module
  studentContest,
  accountSettings,
  contestResult,
  classRoom,
  documentStudent,
};
