import i18n from 'services/i18n';

import reading from 'assets/image/Contest/speaking.png';
import listening from 'assets/image/Contest/listening.png';
import speaking from 'assets/image/Contest/speaking.png';
import writing from 'assets/image/Contest/writing.png';
import top1 from 'assets/image/Contest/top1.png';
import top2 from 'assets/image/Contest/top2.png';
import top3 from 'assets/image/Contest/top3.png';

import headphonesInfor from 'assets/image/student/headphonesInfor.png';
import bookInfor from 'assets/image/student/bookInfor.png';
import penInfor from 'assets/image/student/penInfor.png';
import microphoneInfo from 'assets/image/student/microphoneInfo.png';

import eye from 'assets/image/student/eye.png';
import clock from 'assets/image/student/clock.png';
import headphone from 'assets/image/student/headphone.png';
import instructions from 'assets/image/student/instructions.png';
import microphoneIntructions from 'assets/image/student/microphones.png';
import plan from 'assets/image/student/plan.png';
import test from 'assets/image/student/test.png';
import checkCircle from 'assets/image/student/check_circle.png';
import writingIntructions from 'assets/image/student/writing.png';

export const optionParticipants = [
  //TODO phase 2
  {
    label: i18n.t('contest:options.course1'),
    value: 'course1',
    key: '1'
  },
  {
    label: i18n.t('contest:options.course2'),
    value: 'course2',
    key: '2'
  },
  {
    label: i18n.t('contest:options.course3'),
    value: 'course3',
    key: '3'
  }
];

export const contestLevels = [
  {
    label: i18n.t('questions:options.beginner'),
    stringValue: 'beginner',
    value: 1
  },
  {
    label: i18n.t('questions:options.intermediate'),
    stringValue: 'intermediate',
    value: 2
  },
  {
    label: i18n.t('questions:options.advanced'),
    stringValue: 'advanced',
    value: 3
  }
];

export const contestStatus = {
  DRAFT: 'draft',
  UPCOMING: 'upcoming',
  ONGOING: 'ongoing',
  ENDED: 'ended',
  COMPLETED: 'completed'
};

export const contestType = {
  PRIVATE: 'private',
  PUBLIC: 'public'
};
export const dataListPreview = [
  {
    label: i18n.t('contest:preview.listening'),
    icon: listening,
    type: 'listening'
  },
  {
    label: i18n.t('contest:preview.reading'),
    icon: reading,
    type: 'reading'
  },
  {
    label: i18n.t('contest:preview.writing'),
    icon: writing,
    type: 'writing'
  },
  {
    label: i18n.t('contest:preview.speaking'),
    icon: speaking,
    type: 'speaking'
  }
];

export const dataLeaderBoard = [
  {
    img: top1
  },
  {
    img: top2
  },
  {
    img: top3
  }
];

export const optionsFiterTypeContests = [
  {
    value: 'public',
    label: i18n.t('contest:type.public')
  },
  {
    value: 'private',
    label: i18n.t('contest:type.private')
  }
];

export const optionsFilterStatusContests = [
  {
    value: 'ended',
    label: i18n.t('contest:status.ended')
  },
  {
    value: 'upcoming',
    label: i18n.t('contest:status.upcoming')
  },
  {
    value: 'ongoing',
    label: i18n.t('contest:status.ongoing')
  },
  {
    value: 'draft',
    label: i18n.t('contest:status.draft')
  }
  // {
  //   value: 'completed',
  //   label: i18n.t('contest:status.completed')
  // }
];

export const optionActionContests = [
  {
    key: 'contest',
    label: i18n.t('contest:status.test')
  },
  {
    key: 'practice',
    label: i18n.t('contest:status.practice')
  }
];

export const actionContests = {
  TEST: 'contest',
  PRACTICE: 'practice',
  CONTEST: 'contests'
};

export const optionTypeContests = [
  { label: i18n.t('contest:type.public'), value: 'public' },
  { label: i18n.t('contest:type.private'), value: 'private' }
];

export const actionTypeContests = {
  PUBLIC: 'public',
  PRIVATE: 'private'
};

export const dataListInformationSkill = [
  {
    label: i18n.t('contest:preview.reading'),
    icon: headphonesInfor
  },
  {
    label: i18n.t('contest:preview.listening'),
    icon: bookInfor
  },
  {
    label: i18n.t('contest:preview.speaking'),
    icon: penInfor
  },
  {
    label: i18n.t('contest:preview.writing'),
    icon: microphoneInfo
  }
];

export const dataListGeneralIntructions = [
  {
    label: i18n.t('studentContest:general.carefully'),
    icon: instructions
  },
  {
    label: i18n.t('studentContest:general.listeningSection'),
    icon: headphone
  },
  {
    label: i18n.t('studentContest:general.wordLimit'),
    icon: eye
  },
  {
    label: i18n.t('studentContest:general.beforeListening'),
    icon: instructions
  },
  {
    label: i18n.t('studentContest:general.effectively'),
    icon: clock
  },
  {
    label: i18n.t('studentContest:general.writingAndSpeaking'),
    icon: plan
  },
  {
    label: i18n.t('studentContest:general.unsure'),
    icon: checkCircle
  },
  {
    label: i18n.t('studentContest:general.speakingSection'),
    icon: microphoneIntructions
  },
  {
    label: i18n.t('studentContest:general.answerSheet'),
    icon: writingIntructions
  },
  {
    label: i18n.t('studentContest:general.testConditions'),
    icon: test
  }
];

export const actionStatusContests = {
  DRAFT: 'draft',
  DONE: 'done'
};

export const optionLevelsContests = {
  BEGINNER: 'beginner',
  INTERMEDIATE: 'intermediate',
  ADVANCED: 'advanced'
};

export const optionSkillsContests = {
  LISTENING: 'listening',
  SPEAKING: 'speaking',
  READING: 'reading',
  WRITING: 'writing'
};

export const statusHistoryContestUser = {
  ENROLLED: 'enrolled',
  UNENROLL: 'unenroll'
};

export const statusSkillContestUser = {
  DONE: 'done',
  UNDONE: 'undone'
};

export const optionsStatusforNetWork = {
  COMING_UP: 'comingup',
  ON_GOING: 'ongoing',
  COMPLETED: 'completed',
  DRAFT: 'draft'
};

export const optionSkills = ['listening', 'reading', 'writing', 'speaking'];
