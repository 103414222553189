export default {
  grading: 'Giám sát và Chấm điểm',
  score: 'Điểm số',
  marked: 'Đã chấm',
  unmarked: 'Chưa chấm',
  save: 'Lưu',
  error: 'Lỗi',
  publishScoreSuccess: 'Đã nộp',
  contests: 'Cuộc thi',
  practices: 'Thực hành',
  ended: 'Đã kết thúc',
  upcoming: 'Sắp diễn ra',
  ongoing: 'Đang diễn ra',
  draft: 'Bản nháp',

  list: {
    search: 'Nhập tên cuộc thi',
    all: 'Tất cả',
    submit: 'Nộp',
    submitted: 'Đã nộp',
    level: 'Chọn cấp độ',
    status: 'Chọn trạng thái',
    filter: 'Lọc',
    done: 'Hoàn thành',
    undone: 'Chưa hoàn thành',
    reset: 'Đặt lại',
    textShow: 'Hiển thị',
    view: 'Xem',
    public: 'Công khai',
    private: 'Riêng tư',
    type: 'Chọn loại',
  },

  detail: {
    test: 'Bài kiểm tra',
    submit: 'Nộp',
    participantList: 'Danh sách người tham gia',
    status: 'Trạng thái',
    all: 'Tất cả',
    name: 'Tên',
    level: 'Cấp độ',
    speaking: 'Nói',
    writing: 'Viết',
    submittedAt: 'Nộp lúc',
    action: 'Hành động',
    done: 'Hoàn thành',
    undone: 'Chưa hoàn thành',
    enrolled: 'Đã đăng ký',
    unenroll: 'Hủy đăng ký',
    search: 'Nhập tên học sinh',
    selectLevel: 'Chọn cấp độ',
    selectStatus: 'Chọn trạng thái',
    chooseTime: 'Chọn thời gian',
    submitted: 'Đã nộp',
  },

  gradingStudent: {
    listQuestions: 'Danh sách câu hỏi',
    description: 'Mô tả',
    score: 'Điểm số',
    answer: 'Câu trả lời của học sinh',
    feedback: 'Phản hồi của giáo viên',
    scoreIsRequired: 'Vui lòng nhập điểm',
    scoreMin0Max9: 'Điểm từ 0 đến 9',
    invalid: 'Không hợp lệ',
  },

  practice: {
    search: 'Nhập tên bài thực hành',
    all: 'Tất cả',
    submit: 'Nộp',
    submitted: 'Đã nộp',
    level: 'Chọn cấp độ',
    status: 'Chọn trạng thái',
    filter: 'Lọc',
    done: 'Hoàn thành',
    undone: 'Chưa hoàn thành',
    reset: 'Đặt lại',
    textShow: 'Hiển thị',
    view: 'Xem',
  },
};
