import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import ScrollToTop from 'hooks/ScrollToTop';

import AdminRoute from '../hocs/AdminRoute';
import AuthRoute from '../hocs/AuthRoute';
import StudentRoute from '../hocs/StudentRoute';
import LoginPage from '../containers/OtherPage/LoginPage';

import { ROUTES, ROUTES_ADMIN, ROUTES_AUTH, ROUTES_USER } from './constants';

const OverviewPage = React.lazy(() =>
  import('../containers/TesAdminPage/OverviewPage')
);
const DocumentList = React.lazy(() =>
  import('../containers/TesAdminPage/Documents/List')
);
const DocumentCreate = React.lazy(() =>
  import('../containers/TesAdminPage/Documents/Create')
);
const DocumentUpdate = React.lazy(() =>
  import('../containers/TesAdminPage/Documents/Update')
);
const QuestionCreate = React.lazy(() =>
  import('../containers/TesAdminPage/Questions/Create/Main')
);
const QuestionUpdate = React.lazy(() =>
  import('../containers/TesAdminPage/Questions/Update/Main')
);
const QuestionList = React.lazy(() =>
  import('../containers/TesAdminPage/Questions/List')
);
const FirstLogin = React.lazy(() =>
  import('../containers/OtherPage/FirstLogin')
);
const ForgotPassword = React.lazy(() =>
  import('../containers/OtherPage/ForgotPasswordPage')
);
const ForgotPasswordSuccess = React.lazy(() =>
  import('../containers/OtherPage/ForgotSuccessPasswordPage')
);
const ChangePasswordPage = React.lazy(() =>
  import('../containers/OtherPage/ChangePasswordPage')
);

const EmployeeManager = React.lazy(() =>
  import('../containers/TesAdminPage/EmployeeManager')
);

const FolderList = React.lazy(() =>
  import('../containers/TesAdminPage/Folders')
);

const PreviewQuestion = React.lazy(() =>
  import('../containers/TesAdminPage/Questions/Preview')
);

const ContestList = React.lazy(() =>
  import('../containers/TesAdminPage/ContestManager/List')
);

const ContestCreate = React.lazy(() =>
  import('../containers/TesAdminPage/ContestManager/Create')
);

const ContestUpdate = React.lazy(() =>
  import('../containers/TesAdminPage/ContestManager/Update')
);

const ContestPreview = React.lazy(() =>
  import('../containers/TesAdminPage/ContestManager/Preview')
);

const StudentManager = React.lazy(() =>
  import('../containers/TesAdminPage/StudentManager/List')
);

const OverViewStudent = React.lazy(() =>
  import('../containers/TesAdminPage/StudentManager/OverViewStudent/Main')
);

const OverViewMonitoringAndGrading = React.lazy(() =>
  import(
    '../containers/TesAdminPage/MonitoringAndGrading/contests/OverViewMonitoringAndGrading'
  )
);

const SettingsManager = React.lazy(() =>
  import('../containers/TesAdminPage/Settings/Main')
);
// Student Page
const StudentContestList = React.lazy(() =>
  import('../containers/TesStudentPage/Contest/List/Main')
);

const StudentContestExam = React.lazy(() =>
  import('../containers/TesStudentPage/Contest/Exam/Main')
);

const StudentContestPractice = React.lazy(() =>
  import('../containers/TesStudentPage/Contest/Practice/Main')
);

const StudentContestOverview = React.lazy(() =>
  import('../containers/TesStudentPage/Contest/OverView')
);

const StudentProfile = React.lazy(() =>
  import('../containers/TesStudentPage/AccountSettings/Main')
);

const StudentContestResultOverview = React.lazy(() =>
  import('../containers/TesStudentPage/Result/OverView')
);

const StudentContestResult = React.lazy(() =>
  import('../containers/TesStudentPage/Result/Result/Main')
);

const AdminContestResultOverview = React.lazy(() =>
  import(
    '../containers/TesAdminPage/StudentManager/OverViewStudent/HistoryContest/Result/OverView'
  )
);

const AdminContestResult = React.lazy(() =>
  import(
    '../containers/TesAdminPage/StudentManager/OverViewStudent/HistoryContest/Result/Result/Main'
  )
);

const AdminClassManagement = React.lazy(() =>
  import('../containers/TesAdminPage/Class/List')
);

const LeaderBoard = React.lazy(() =>
  import('../containers/TesAdminPage/LeaderBoard/Main')
);

const FolderTreeOfClass = React.lazy(() =>
  import('../containers/TesAdminPage/Class/folderTree/Main')
);

const DocumentsOfStudent = React.lazy(() =>
  import('../containers/TesStudentPage/Documents')
);

// Monitoring and Grading
const MonitoringAndGrading = React.lazy(() =>
  import('../containers/TesAdminPage/MonitoringAndGrading/Main/index')
);

const GradingStudent = React.lazy(() =>
  import(
    '../containers/TesAdminPage/MonitoringAndGrading/contests/Grading/Main'
  )
);

const Routes = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Switch>
        {/* None auth */}
        <AuthRoute exact path={ROUTES_AUTH.LOGIN} component={LoginPage} />
        <AuthRoute
          exact
          path={ROUTES_AUTH.FIRST_LOGIN}
          component={FirstLogin}
        />
        <AuthRoute
          exact
          path={ROUTES_AUTH.FORGOT_PASS_PAGE}
          component={ForgotPassword}
        />
        <AuthRoute
          exact
          path={ROUTES_AUTH.FORGOT_PASSWORD_SUCCESS}
          component={ForgotPasswordSuccess}
        />
        <AuthRoute
          exact
          path={ROUTES_AUTH.CHANGE_PASSWORD_PAGE}
          component={ChangePasswordPage}
        />
        {/* Auth Route */}
        {/* ADMIN Route */}
        <AdminRoute exact path={ROUTES.HOME} component={OverviewPage} />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.DOCUMENTS}
          component={DocumentList}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.DOCUMENTS_CREATE}
          component={DocumentCreate}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.DOCUMENTS_UPDATE}
          component={DocumentUpdate}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.QUESTIONS_CREATE_LISTENING}
          component={QuestionCreate}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.QUESTIONS_CREATE_READING}
          component={QuestionCreate}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.QUESTIONS_CREATE_WRITING}
          component={QuestionCreate}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.QUESTIONS_CREATE_SPEAKING}
          component={QuestionCreate}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.QUESTIONS_UPDATE}
          component={QuestionUpdate}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.DOCUMENTS}
          component={DocumentList}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.DOCUMENTS_CREATE}
          component={DocumentCreate}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.DOCUMENTS_UPDATE}
          component={DocumentUpdate}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.EMPLOYEE}
          component={EmployeeManager}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.QUESTION}
          component={QuestionList}
        />
        <AdminRoute exact path={ROUTES_ADMIN.FOLDER} component={FolderList} />
        <AdminRoute exact path={ROUTES_ADMIN.CONTEST} component={ContestList} />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.CONTEST_CREATE}
          component={ContestCreate}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.CONTEST_EDIT}
          component={ContestUpdate}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.QUESTION_PREVIEW}
          component={PreviewQuestion}
        />
        {/* CONTEST PREVIEW */}
        <AdminRoute
          exact
          path={ROUTES_ADMIN.CONTEST_PREVIEW}
          component={ContestPreview}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.STUDENT}
          component={StudentManager}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.STUDENT_OVERVIEW}
          component={OverViewStudent}
        />
        {/* GRADING */}
        <AdminRoute
          exact
          path={ROUTES_ADMIN?.GRADING}
          component={MonitoringAndGrading}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.GRADING_OVERVIEW}
          component={OverViewMonitoringAndGrading}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.GRADING_STUDENT}
          component={GradingStudent}
        />
        {/* SETTING */}
        <AdminRoute
          exact
          path={ROUTES_ADMIN.SETTINGS}
          component={SettingsManager}
        />
        {/* //STUDENT */}
        <StudentRoute
          exact
          path={ROUTES_USER.CONTEST}
          component={StudentContestList}
        />
        {/* //Contest exam */}
        <StudentRoute
          exact
          path={ROUTES_USER.CONTEST_EXAM}
          component={StudentContestExam}
        />

        <StudentRoute
          exact
          path={ROUTES_USER.CONTEST_PRACTICE}
          component={StudentContestPractice}
        />

        <StudentRoute
          exact
          path={ROUTES_USER.CONTEST_OVERVIEW}
          component={StudentContestOverview}
        />

        <StudentRoute
          exact
          path={ROUTES_USER.STUDENT_PROFILE}
          component={StudentProfile}
        />
        <StudentRoute
          exact
          path={ROUTES_USER.STUDENT_RESULT_OVERVIEW}
          component={StudentContestResultOverview}
        />

        <StudentRoute
          exact
          path={ROUTES_USER.STUDENT_RESULT}
          component={StudentContestResult}
        />

        {/* ADMIN VIEW HISTORY */}
        <AdminRoute
          exact
          path={ROUTES_ADMIN.ADMIN_RESULT_OVERVIEW}
          component={AdminContestResultOverview}
        />

        <AdminRoute
          exact
          path={ROUTES_ADMIN.ADMIN_RESULT}
          component={AdminContestResult}
        />

        <AdminRoute
          exact
          path={ROUTES_ADMIN.CLASS}
          component={AdminClassManagement}
        />

        <AdminRoute
          exact
          path={ROUTES_ADMIN.LEADER_BOARD}
          component={LeaderBoard}
        />

        <AdminRoute
          exact
          path={ROUTES_ADMIN.CLASS_FOLDER}
          component={FolderTreeOfClass}
        />

        <StudentRoute
          exact
          path={ROUTES_USER.DOCUMENTS}
          component={DocumentsOfStudent}
        />
      </Switch>
    </ScrollToTop>
  </BrowserRouter>
);

export default Routes;
