const preAdmin = '/admin';
const preAuth = '/auth';
const preUser = '/student';

export const ROUTES = {
  HOME: `/`
};

export const ROUTES_AUTH = {
  LOGIN: `${preAuth}/login`,
  FIRST_LOGIN: `${preAuth}/first-login`,
  FORGOT_PASS_PAGE: `${preAuth}/forgot-password`,
  FORGOT_PASSWORD_SUCCESS: `${preAuth}/forgot-password-success`,
  CHANGE_PASSWORD_PAGE: `${preAuth}/change-password`
};

export const ROUTES_ADMIN = {
  // Documents
  DOCUMENTS: `${preAdmin}/documents`,
  DOCUMENTS_CREATE: `${preAdmin}/documents/create`,
  DOCUMENTS_UPDATE: `${preAdmin}/documents/:id`,

  // Employee
  EMPLOYEE: `${preAdmin}/employee`,

  // Question
  QUESTION: `${preAdmin}/questions`,
  QUESTIONS_CREATE_LISTENING: `${preAdmin}/questions/create/listening`,
  QUESTIONS_CREATE_READING: `${preAdmin}/questions/create/reading`,
  QUESTIONS_CREATE_WRITING: `${preAdmin}/questions/create/writing`,
  QUESTIONS_CREATE_SPEAKING: `${preAdmin}/questions/create/speaking`,
  QUESTIONS_UPDATE: `${preAdmin}/questions/update/:skill/:id`,

  QUESTION_PREVIEW: `${preAdmin}/questions/preview/:skill/:id`,

  // Folder
  FOLDER: `${preAdmin}/folders`,

  // Contest
  CONTEST: `${preAdmin}/contest/:utilization`,
  CONTEST_CREATE: `${preAdmin}/contest/create/:utilization`,
  CONTEST_EDIT: `${preAdmin}/contest/update/:utilization/:id`,
  CONTEST_PREVIEW: `${preAdmin}/contest/preview/:utilization/:id`,

  //STUDENT MANAGEMENT
  STUDENT: `${preAdmin}/student`,
  STUDENT_OVERVIEW: `${preAdmin}/student/:id`,

  //MONITORING AND GRADING
  GRADING: `${preAdmin}/grading/:utilization`,
  GRADING_OVERVIEW: `${preAdmin}/grading/:utilization/:type/:id/overview/`,
  // GRADING_STUDENT: `${preAdmin}/grading/:type/:id/student/:student`,
  GRADING_STUDENT: `${preAdmin}/grading/:utilization/:type/:id/student/:student`,

  //SETTINGS MANAGEMENT
  SETTINGS: `${preAdmin}/settings`,

  //HOSTORY MANAGEMENT
  ADMIN_RESULT_OVERVIEW: `${preAdmin}/student/result/:utilization/:id_student/:id_contest`,
  ADMIN_RESULT: `${preAdmin}/student/result/:utilization/:id_student/:id_contest/:skill`,

  //CLASS
  CLASS: `${preAdmin}/class`,

  //LEADERBOARD
  LEADER_BOARD: `${preAdmin}/leaderBoard`,

  //Class  view list folder
  CLASS_FOLDER: `${preAdmin}/class/folder/:id`
};

export const ROUTES_USER = {
  CONTEST: `${preUser}/contest/:utilization`,
  // CONTEST_OVERVIEW: `${preUser}/contest/overview/:id`,
  CONTEST_EXAM: `${preUser}/contest/exam/start/:utilization/:id`,
  CONTEST_PRACTICE: `${preUser}/contest/start/:utilization/:id`,

  //STUDENT CONTEST
  CONTEST_OVERVIEW: `${preUser}/contest/:utilization/:id`,

  //STUDENT PROFILE
  STUDENT_PROFILE: `${preUser}/profile`,
  STUDENT_RESULT_OVERVIEW: `${preUser}/result/:utilization/:id`,
  STUDENT_RESULT: `${preUser}/result/:utilization/:id/:skill`,
  DOCUMENTS: `${preUser}/:utilization`
};

export { preAdmin };
