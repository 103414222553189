import { generatePath } from 'react-router-dom';

import { ROUTES_ADMIN } from 'routes/constants';

import userIcon from 'assets/icons/user/user.svg';
import fileIcon from 'assets/icons/user/file.svg';
import folderIcon from 'assets/icons/user/folder.svg';
import questionIcon from 'assets/icons/user/question.svg';
import examIcon from 'assets/icons/user/exam.svg';
import championIcon from 'assets/icons/user/champion.svg';
import student from 'assets/icons/user/student.svg';
import settings from 'assets/icons/user/settings.svg';
import classIcon from 'assets/icons/user/class.svg';
import leaderboardIcon from 'assets/icons/user/leaderboard.svg';
import { actionContests } from 'data/Contest';

export const data = [
  {
    id: 'P1',
    title: 'documents',
    icon: fileIcon,
    link: ROUTES_ADMIN.DOCUMENTS,
    keyword: 'documents',
    permissionSidebarKey: 'Assets',
  },
  {
    id: 'P2',
    title: 'folders',
    icon: folderIcon,
    link: ROUTES_ADMIN.FOLDER,
    keyword: 'folders',
    permissionSidebarKey: 'AssetFolders',
  },
  {
    id: 'P3',
    title: 'questions',
    icon: questionIcon,
    link: ROUTES_ADMIN.QUESTION,
    keyword: 'questions',
    permissionSidebarKey: 'Question',
  },
  {
    id: 'P4',
    title: 'contest',
    icon: examIcon,
    link: `${generatePath(ROUTES_ADMIN.CONTEST, {
      utilization: actionContests?.TEST,
    })}`,
    keyword: 'contest',
    permissionSidebarKey: 'Exams',
  },
  {
    id: 'P5',
    title: 'employee',
    icon: userIcon,
    link: ROUTES_ADMIN.EMPLOYEE,
    keyword: 'employee',
    permissionSidebarKey: 'User',
  },
  {
    id: 'P6',
    title: 'Monitoring and Grading',
    icon: championIcon,
    link: `${generatePath(ROUTES_ADMIN?.GRADING, {
      utilization: actionContests?.CONTEST
    })}`,
    keyword: 'grading',
    permissionSidebarKey: 'StudentExamHistory',
  },
  {
    id: 'P7',
    title: 'student',
    icon: student,
    link: ROUTES_ADMIN.STUDENT,
    keyword: 'student',
    permissionSidebarKey: 'Student',
  },
  {
    id: 'P8',
    title: 'class',
    icon: classIcon,
    link: ROUTES_ADMIN.CLASS,
    keyword: 'class',
    permissionSidebarKey: 'Class',
  },
  {
    id: 'P9',
    title: 'leaderBoard',
    icon: leaderboardIcon,
    link: ROUTES_ADMIN.LEADER_BOARD,
    keyword: 'leaderBoard',
    permissionSidebarKey: 'Leaderboard',
  },
  {
    id: 'P10',
    title: 'settings',
    icon: settings,
    link: ROUTES_ADMIN.SETTINGS,
    keyword: 'settings',
    permissionSidebarKey: 'settings',
  },
];
